<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal">
                        <a-form-item style="margin-bottom:10px;">
                            <a-form layout="inline">
                                <a-row>

                                    <a-col :span="20">
                                        <a-form-item>
                                            <label class="yj-partner-check_lab">激励类型：</label>
                                            <a-radio-group v-model="PromotionType" @change="getTableData">
                                                <a-radio-button value="-1">全部</a-radio-button>
                                                <a-radio-button value="0">拉新</a-radio-button>
                                                <a-radio-button value="1">购买</a-radio-button>
                                                <a-radio-button value="2">领券</a-radio-button>
                                                <a-radio-button value="3">阅读</a-radio-button>
                                            </a-radio-group>
                                        </a-form-item>

                                    </a-col>     <a-col :span="4" align='right'>
                                        <a-button icon='plus' type="primary" @click="Detail(0,0)"> 增加规则</a-button>
                                    </a-col>
                                </a-row>

                            </a-form>
                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                            <span slot="action" slot-scope="text, record">
                                <a v-if="record.PublishStatus==0" @click="Detail(record.ID,0)">编辑</a>
                                <a v-if="record.PublishStatus!=0" @click="Detail(record.ID,1)">详情</a>
                                <a-divider type="vertical" />
                                <a v-if="record.PublishStatus==0" @click="Update(record.ID,1)">发布</a>
                                <a v-if="record.PublishStatus==1" @click="Update(record.ID,2)">结束</a>
                                <a-divider v-if="record.PublishStatus==1" type="vertical" />
                                <a-divider v-if="record.PublishStatus==0" type="vertical" />
                                <a @click="showtaskDetail(record.ID)">明细</a>
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "激励类型",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.PromotionType) {
                                case 0:
                                    rt = "拉新";
                                    break;
                                case 1:
                                    rt = "购买";
                                    break;
                                case 2:
                                    rt = "领券";
                                    break;
                                case 3:
                                    rt = "阅读";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "奖励",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.AwardType) {
                                case 0:
                                    rt = "奖励红包" + util.ToFixed(item.LuckMoney, "") + "元";
                                    break;
                                case 1:
                                    rt = item.AwardPool[0].Name;
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "目标用户类型",
                        dataIndex: "TargetUserType",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.TargetUserType) {
                                case 0:
                                    rt = "分享人";
                                    break;
                                case 1:
                                    rt = "阅读人";
                                    break;
                                case 2:
                                    rt = "团员";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "目标数量",
                        dataIndex: "TargetCount",
                    },

                    {
                        title: "创建时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy年MM月dd日')
                        }
                    },

                    {
                        title: "激励时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.BeginTime, 'yyyy年MM月dd日') + "-" + util.TimeStamptoDateTime(item.EndTime, 'yyyy年MM月dd日 hh:mm:ss')
                        }
                    },
                    {
                        title: "状态",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.PublishStatus) {
                                case 0:
                                    rt = "未生效";
                                    break;
                                case 1:
                                    rt = "已生效";
                                    break;
                                case 2:
                                    rt = "已结束";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: '操作',
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PromotionType: "-1"
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetPromoteStimulateSharerConfigList",
                    data: {
                        pageIndex: self.pagination.current,
                        PromotionType: self.PromotionType,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },


            Detail(ID, disabled) {
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateSharerConfig_Detail",
                    query: { ID: ID, disabled: disabled }
                });
            },
            showtaskDetail(ID) {
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateSharerConfig_DetailInfo",
                    query: { ID: ID }
                });
            },

            Update(ID, e) {
                var self = this;
                var con = e != 2 ? '发布' : '结束';
                this.$confirm({
                    title: '提示',
                    content: '是否确定' + con,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Promote/EditPromoteStimulateSharerConfigPublishStatus",
                            data: {
                                ID: ID,
                                PublishStatus: e
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.$message.success(con + "成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>